import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  createContext,
} from 'react';
import { useIntl } from 'react-intl';
import { last } from 'lodash';

import { ActivityLog } from '@agoy/activity-log';
import { useApiSdk, asResultClass } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { isInputCorrect } from 'utils';
import { Period, ReconciliationBalanceAccountRow } from '@agoy/api-sdk-core';
import { InputData } from '_reconciliation/types';
import sendActivityEvent from 'Api/Activity/authorizationActivityEventHandler';
import { addGlobalErrorMessage } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { mapToSpecification } from '_reconciliation/util/mapSpecification';

import { formatCurrentTime, parseFormat, reformat } from '@agoy/dates';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import { HistoryItemType } from './Rows/EventView/SaldoHistory';

import { onUserInputCallbackFunction } from './Rows/types';

import {
  createUserInputData,
  getFormattedPeriod,
} from './HiddenGroupRow/utils';

interface SaldoHistoryProviderProps {
  account: string;
  period: ReconciliationPeriod;
  accountBalance: ReconciliationBalanceAccountRow;
  inputData: InputData;
  children: React.ReactNode | React.ReactNode[];
}

export type ContextType = {
  saldoHistory: HistoryItemType[];
  onSaldoChange: (
    accountNumber: string,
    period: Period,
    value: number | undefined
  ) => void;
  fetchSaldoHistory: (period: Period) => Promise<void>;
  toggleSaldoToCorrect: (accountNumber: string, period: Period) => void;
  checkedHistory: CheckedHistoryItemType[];
  onChangeCheckedValue: (
    accountNumber: string,
    period: Period,
    value: boolean
  ) => Promise<void>;
  fetchCheckedHistory: (period: Period) => Promise<void>;
  onUserInputChange: onUserInputCallbackFunction;
};

export const SaldoHistoryContext = createContext<ContextType>({
  saldoHistory: [],
  onSaldoChange: () => {},
  fetchSaldoHistory: async () => {},
  toggleSaldoToCorrect: () => {},
  checkedHistory: [],
  onChangeCheckedValue: async () => {},
  fetchCheckedHistory: async () => {},
  onUserInputChange: () => {},
});

export interface CheckedHistoryItemType {
  checked: boolean;
  date: string;
  authorName: string;
}

const MAX_LENGTH = 1;

const UserInputHistoryProvider = ({
  account,
  period,
  accountBalance,
  inputData,
  children,
}: SaldoHistoryProviderProps): JSX.Element => {
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [saldoHistory, setSaldoHistory] = useState<HistoryItemType[]>([]);
  const [checkedHistory, setCheckedHistory] = useState<
    CheckedHistoryItemType[]
  >([]);
  const [currentUserData, setCurrentUserData] =
    useState<{ inputData: InputData | undefined; account; periodId: number }>();

  const { clientId } = period;

  const supportUser = useSelector((state) => state.user.supportUser);

  const userName = useSelector((state) => state.user.fullName || '');

  const lastPeriod = last(period.periods);

  const periodInformation = useMemo(
    () =>
      lastPeriod
        ? [
            {
              period: getFormattedPeriod(lastPeriod),
              ib: accountBalance.ib,
              ub: accountBalance.ub,
              account: accountBalance.number.toString(),
              change: accountBalance.ub - accountBalance.ib,
              periodId: lastPeriod.id,
            },
          ]
        : [],
    [accountBalance, lastPeriod]
  );

  const userData: InputData | undefined = useMemo(() => {
    if (lastPeriod) {
      const userAccountInput = createUserInputData(periodInformation, {
        [lastPeriod.id]: inputData,
      });

      return userAccountInput[lastPeriod.id];
    }

    return undefined;
  }, [inputData, lastPeriod, periodInformation]);

  const putActualBalance = useCallback(
    async (
      inAccount: number,
      inPeriod: Period,
      data: { checked?: boolean; balance?: number | null }
    ) => {
      if (supportUser) {
        return;
      }

      if (!lastPeriod) {
        return;
      }

      const result = await asResultClass(
        sdk.putActualBalance({
          clientid: clientId,
          periodId: inPeriod.id,
          preliminary: inPeriod.preliminary ?? false,
          account: [inAccount],
          requestBody: data,
        })
      );
      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [supportUser, lastPeriod, sdk, clientId, dispatch]
  );

  const onUserInputChange: onUserInputCallbackFunction = async (
    data,
    accountNumber,
    inPeriod
  ) => {
    if (supportUser) {
      return;
    }

    if (data.specification) {
      asResultClass(
        sdk.updateSpecifications({
          clientid: clientId,
          accountNumber: parseInt(accountNumber, 10),
          periodId: inPeriod.id,
          requestBody: data.specification.map(mapToSpecification),
        })
      ).then((result) => {
        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      });
    }

    if (
      data.cashView ||
      data.inventoryView ||
      data.comment !== undefined ||
      data.printComment !== undefined ||
      data.visited !== undefined
    ) {
      asResultClass(
        sdk.putUserInput({
          clientid: clientId,
          accountNumber: parseInt(accountNumber, 10),
          periodId: inPeriod.id,
          requestBody: {
            cashView: data.cashView,
            inventoryView: data.inventoryView,
            comment: data.comment,
            printComment: data.printComment,
            visited: data.visited,
          },
        })
      ).then((result) => {
        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      });
    }
  };

  // Checked history

  const fetchCheckedHistory = useCallback(
    async (period: Period) => {
      if (clientId) {
        const result = await asResultClass(
          sdk.getCheckedChangeHistory({
            clientid: clientId,
            period: parseFormat(period.start, 'yyyyMM'),
            accounts: [account],
            limit: 1,
          })
        );
        if (result.ok) {
          setCheckedHistory(result.val);
        }
      }
    },
    [account, clientId, sdk]
  );

  const updateCheckedHistory = useCallback(
    async (accountNumber: string, period: Period, value: boolean) => {
      if (supportUser) {
        return;
      }
      setCheckedHistory([
        {
          checked: value,
          date: formatCurrentTime(),
          authorName: userName,
        },
      ]);

      if (clientId) {
        const formattedPeriod = reformat(period.start, 'yyyyMMdd', 'MMMM yyyy');
        const periodDate =
          formattedPeriod.charAt(0).toLocaleUpperCase() +
          formattedPeriod.slice(1);

        await sendActivityEvent(
          ActivityLog.createActivityLogEvent({
            clientId,
            program: 'RECONCILIATION',
            section: 'HIDDEN_ROW',
            resource: 'CHECKED',
            operation: 'UPDATE',
            arguments: [
              periodDate,
              userName,
              value
                ? ''
                : formatMessage({
                    id: 'RECONCILIATION.HIDDEN_ROW.CHECKED.UPDATE.negative',
                  }),
              accountNumber,
            ],
          })
        );
      }
    },
    [clientId, formatMessage, supportUser, userName]
  );

  const updateSaldoHistory = useCallback(
    (period: Period, value?: number | null) => {
      if (supportUser) {
        return;
      }

      if (value !== undefined) {
        let updatedHistoryItems = [
          {
            saldo: value,
            createdAt: formatCurrentTime(),
            authorName: userName,
          },
        ];

        if (saldoHistory?.[period.start]) {
          updatedHistoryItems = [
            ...updatedHistoryItems,
            ...saldoHistory[period.start],
          ];
        }

        setSaldoHistory(updatedHistoryItems.slice(0, MAX_LENGTH));
      }
    },
    [saldoHistory, supportUser, userName]
  );

  useEffect(() => {
    if (!lastPeriod || currentUserData?.inputData === userData) {
      return;
    }

    if (
      userData &&
      currentUserData &&
      currentUserData.inputData &&
      currentUserData.account === account &&
      currentUserData.periodId === lastPeriod.id
    ) {
      if (currentUserData.inputData.saldo !== userData.saldo) {
        updateSaldoHistory(
          lastPeriod,
          userData.saldo ? parseInt(userData.saldo, 10) : null
        );
      }

      if (currentUserData.inputData.checked !== userData.checked) {
        updateCheckedHistory(account, lastPeriod, userData.checked);
      }
    }

    setCurrentUserData({
      inputData: userData,
      account,
      periodId: lastPeriod.id,
    });
  }, [
    account,
    currentUserData,
    lastPeriod,
    updateCheckedHistory,
    updateSaldoHistory,
    userData,
  ]);

  const onChangeCheckedValue = async (
    accountNumber: string,
    period: Period,
    value: boolean
  ) => {
    await putActualBalance(parseInt(accountNumber, 10), period, {
      checked: value,
    });
  };

  const fetchSaldoHistory = useCallback(
    async (period: Period): Promise<void> => {
      if (clientId) {
        const response = await asResultClass(
          sdk.getSaldoChangeHistory({
            clientid: clientId,
            periodId: period.id,
            accounts: [parseInt(account, 10)],
            limit: MAX_LENGTH,
          })
        );

        if (response.ok) {
          const formattedHistory = response.val.map((item) => ({
            saldo: item.saldo,
            createdAt: item.createdAt,
            authorName: item.author.fullName,
          }));

          setSaldoHistory(formattedHistory);
        }
      }
    },
    [account, clientId, sdk]
  );

  const toggleSaldoToCorrect = (accountNumber: string, inPeriod: Period) => {
    const { ub, saldo } = userData || {};

    const isCorrect =
      ub !== undefined && saldo !== undefined
        ? isInputCorrect(`${saldo}`, ub)
        : false;

    const value = isCorrect ? null : ub;

    putActualBalance(parseInt(accountNumber, 10), inPeriod, {
      balance: value,
      checked: false,
    });
  };

  const onSaldoChange = (
    accountNumber: string,
    inPeriod: Period,
    value: number | undefined
  ) => {
    const { saldo, checked } = userData || {};

    const isCorrect =
      value !== undefined && saldo !== undefined
        ? isInputCorrect(`${saldo}`, value)
        : false;

    putActualBalance(parseInt(accountNumber, 10), inPeriod, {
      balance: value ?? null,
      checked: checked && isCorrect,
    });
  };

  return (
    <SaldoHistoryContext.Provider
      value={{
        saldoHistory,
        onSaldoChange,
        fetchSaldoHistory,
        toggleSaldoToCorrect,
        checkedHistory,
        onChangeCheckedValue,
        fetchCheckedHistory,
        onUserInputChange,
      }}
    >
      {children}
    </SaldoHistoryContext.Provider>
  );
};

export default UserInputHistoryProvider;
