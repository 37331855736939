import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { orderBy } from 'lodash';
import { ClientCompany } from '@agoy/api-sdk-core';
import { isSameOrAfter, parse } from '@agoy/dates';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import { indexByKey } from 'utils/arrays';
import { comparePeriodChanges, getOriginalGroup } from './utils';
import { MovedAccountsByPeriods, MovedRows } from './types';
import { getGroupedPeriods, getType } from '../utils';
import { getClosestDate } from '../Columns/PeriodColumn/utils';
import generateCustomAccountGroups from './generateCustomAccountGroups';

type RowContextType = {
  movedAccounts: MovedRows;
  localMovedAccounts: MovedRows;
  movedAccountsByPeriods: MovedAccountsByPeriods;
  moveRow: (
    accountNumber: string,
    periods: string[],
    fromGroupId: string,
    toGroupId: string
  ) => void;
  restoreChanges: () => void;
  saveModalIsOpen: boolean;
  setSaveModalIsOpen: (v: boolean) => void;
  saveChanges: () => Promise<void>;
};

const MoveAccountsContext = React.createContext<RowContextType>({
  movedAccounts: {},
  localMovedAccounts: {},
  movedAccountsByPeriods: {},
  moveRow: () => {
    throw new Error('No context');
  },
  restoreChanges: () => {
    throw new Error('No context');
  },
  saveModalIsOpen: false,
  setSaveModalIsOpen: () => {
    throw new Error('No context');
  },
  saveChanges: async () => {
    throw new Error('No context');
  },
});

type MoveAccountsContextProviderProps = {
  clientId: string;
  financialYear: ClientCompany['financialYears'][0];
  children: React.ReactNode;
};

const mergeMovedAccounts = (
  movedRows: MovedRows,
  localMovedRows: MovedRows
) => {
  const merged = { ...movedRows };

  Object.entries(localMovedRows).forEach(([accountNumber, movedPeriods]) => {
    Object.entries(movedPeriods).forEach(([period, movingData]) => {
      merged[accountNumber] = {
        ...merged[accountNumber],
        [period]: movingData,
      };
    });
  });

  return merged;
};

export const MoveAccountsContextProvider = ({
  clientId,
  financialYear,
  children,
}: MoveAccountsContextProviderProps) => {
  const sdk = useApiSdk();

  const notificationService = useContext(NotificationContext);

  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [movedAccounts, setMovedAccounts] = useState<MovedRows>({});
  const [localMovedAccounts, setLocalMovedAccounts] = useState<MovedRows>({});

  const mergedMovedAccounts = useMemo(
    () => mergeMovedAccounts(movedAccounts, localMovedAccounts),
    [localMovedAccounts, movedAccounts]
  );

  const { rawFinancialYears, closingPeriod, type } = useSelector(
    (state) => state.customers[clientId]
  );
  const userFullName = useSelector((state) => state.user.fullName);
  const periodsByStart = indexByKey(financialYear.periods, 'start');

  const financialYears = useMemo(
    () => orderBy(rawFinancialYears, 'start'),
    [rawFinancialYears]
  );

  const allPeriods: ReconciliationPeriod[] = useMemo(() => {
    const periodType = getType(closingPeriod || 'month');

    return getGroupedPeriods(
      clientId,
      periodType,
      financialYear,
      financialYears
    );
  }, [clientId, closingPeriod, financialYear, financialYears]);

  const movedAccountsByPeriods = useMemo(() => {
    const accounts = Object.keys(mergedMovedAccounts);

    const res = accounts.reduce((prev, accountNumber) => {
      const periodsResult = allPeriods.reduce((prevPeriods, period) => {
        const movedPeriods = mergedMovedAccounts[accountNumber];
        const closestPeriod = getClosestDate(
          period.start,
          Object.keys(movedPeriods)
        );

        return {
          ...prevPeriods,
          [period.start]: closestPeriod
            ? movedPeriods[closestPeriod].toGroupId
            : getOriginalGroup(accountNumber, type),
        };
      }, {});

      return { ...prev, [accountNumber]: periodsResult };
    }, {});

    return res;
  }, [allPeriods, type, mergedMovedAccounts]);

  const getMovedAccounts = useCallback(async () => {
    const result = await asResultClass(
      sdk.getCustomAccountGroups({
        clientid: clientId,
        financialYearId: financialYear.id,
      })
    );

    if (!result.ok) {
      return;
    }

    const periodsById =
      financialYear.periods?.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: item,
        }),
        {}
      ) || {};

    const movedAccountsData = {};

    result.val.forEach(({ modifiedGroups, periodId }) => {
      modifiedGroups.forEach(
        ({ accountIds, groupId, updatedAt, updatedBy }) => {
          accountIds.forEach((accountNumber) => {
            movedAccountsData[accountNumber] = {
              ...movedAccountsData[accountNumber],
              [periodsById[periodId].start]: {
                fromGroupId: getOriginalGroup(accountNumber.toString(), type),
                toGroupId: groupId,
                updatedAt,
                updatedBy,
              },
            };
          });
        }
      );
    });

    setMovedAccounts(movedAccountsData);
  }, [clientId, type, financialYear, sdk]);

  useEffect(() => {
    getMovedAccounts();
  }, [getMovedAccounts]);

  useEffect(() => {
    if (!notificationService) {
      return () => {};
    }
    const subscription = notificationService.subscribe(
      { clientId, topic: 'accounting-balances-changed' },
      (notification) => {
        if (notification.ok) {
          const msg = notification.val;

          if (
            msg.topic === 'accounting-balances-changed' &&
            msg.financialYearId === financialYear.id &&
            msg.clientId === clientId &&
            msg.isAccountsMoved
          ) {
            getMovedAccounts();
          }
        }
      }
    );

    return () => {
      notificationService.unsubscribe(subscription);
    };
  }, [notificationService, clientId, financialYear.id, getMovedAccounts]);

  const moveRow = useCallback(
    (
      accountNumber: string,
      periods: string[],
      fromGroupId: string,
      toGroupId: string
    ) => {
      const accounts = { ...localMovedAccounts };
      const account = accounts[accountNumber] || {};

      periods.forEach((period) => {
        // we moved account for this period already
        if (account[period]) {
          // if we move it back need to delete changes for this period
          if (account[period].fromGroupId === toGroupId) {
            delete accounts[accountNumber][period];
          } else {
            // if we move it to new place
            accounts[accountNumber][period].toGroupId = toGroupId;
          }
        } else {
          // check if we move account to this group again,
          // in this case remove previous period changes
          const existingGroupPeriodKeys = Object.keys(account).filter(
            (periodKey) => account[periodKey].toGroupId === toGroupId
          );

          existingGroupPeriodKeys.forEach((periodKey) => {
            if (isSameOrAfter(parse(periodKey), parse(period))) {
              delete accounts[accountNumber][periodKey];
            }
          });

          accounts[accountNumber] = {
            ...account,
            [period]: {
              fromGroupId,
              toGroupId,
              updatedAt: new Date().toISOString(),
              updatedBy: userFullName || '',
            },
          };
        }
      });

      setLocalMovedAccounts(accounts);
    },
    [localMovedAccounts, userFullName]
  );

  const saveMovedAccounts = useCallback(async () => {
    if (!Object.keys(localMovedAccounts).length) {
      return;
    }

    const storedCustomAccountGroups = await sdk.getCustomAccountGroups({
      clientid: clientId,
      financialYearId: financialYear.id,
    });

    const newCustomAccountGroups = generateCustomAccountGroups(
      localMovedAccounts,
      movedAccounts,
      periodsByStart
    );

    const storedCustomAccountGroupsByPeriod = indexByKey(
      storedCustomAccountGroups,
      'periodId'
    );

    const changedPeriodIds = [
      ...Object.keys(newCustomAccountGroups),
      ...Object.keys(storedCustomAccountGroupsByPeriod),
    ].map(Number);

    await Promise.all(
      changedPeriodIds.map((periodId) => {
        if (newCustomAccountGroups[periodId]) {
          if (storedCustomAccountGroupsByPeriod[periodId]) {
            if (
              !comparePeriodChanges(
                newCustomAccountGroups[periodId],
                storedCustomAccountGroupsByPeriod[periodId]
              )
            ) {
              return sdk.updateCustomAccountGroup({
                clientid: clientId,
                financialYearId: financialYear.id,
                periodId,
                requestBody: {
                  modifiedGroups: newCustomAccountGroups[periodId],
                },
              });
            }

            return new Promise((resolve) => resolve(null));
          }
          return sdk.createCustomAccountGroup({
            clientid: clientId,
            financialYearId: financialYear.id,
            periodId,
            requestBody: { modifiedGroups: newCustomAccountGroups[periodId] },
          });
        }
        return sdk.deleteCustomAccountGroup({
          clientid: clientId,
          financialYearId: financialYear.id,
          periodId,
        });
      })
    );
    await getMovedAccounts();
    setLocalMovedAccounts({});
  }, [
    clientId,
    financialYear.id,
    getMovedAccounts,
    localMovedAccounts,
    movedAccounts,
    periodsByStart,
    sdk,
  ]);

  const rowContext = useMemo((): RowContextType => {
    return {
      localMovedAccounts,
      movedAccounts: mergedMovedAccounts,
      movedAccountsByPeriods,
      moveRow,
      restoreChanges: () => setLocalMovedAccounts({}),
      saveModalIsOpen,
      setSaveModalIsOpen,
      saveChanges: saveMovedAccounts,
    };
  }, [
    localMovedAccounts,
    mergedMovedAccounts,
    moveRow,
    movedAccountsByPeriods,
    saveModalIsOpen,
    saveMovedAccounts,
  ]);

  return (
    <MoveAccountsContext.Provider value={rowContext}>
      {children}
    </MoveAccountsContext.Provider>
  );
};

export default MoveAccountsContext;
